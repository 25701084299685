import React from "react"
import { graphql } from "gatsby"

import { useAnalytics } from "../hooks"

import {
  locationType,
  courseType,
  courseGroupType,
  courseBookingType,
} from "../types"
import { CourseGroupPage } from "../components"
import { locationSlug } from "../utilities"

interface Props {
  data: { contentfulLocation: locationType } & {
    allContentfulCourseBookings: {
      edges: Array<{
        node: courseBookingType
      }>
    }
  }
}

export default ({ data }: Props) => {
  const analytics = useAnalytics()
  analytics.track(["User", "Page View"], {
    page: locationSlug(data.contentfulLocation.slug),
  })

  const url =
    process.env.GATSBY_SITE_URL + locationSlug(data.contentfulLocation.slug)

  const filteredBookings = data.allContentfulCourseBookings.edges.filter(
    ({ node }) =>
      node.locations &&
      node.locations.length &&
      node.locations.some(
        (location) =>
          location.contentful_id === data.contentfulLocation.contentful_id
      )
  )
  // Structuring Course Location the same as Course Group so the same component can be used
  const createProxyCourseGroup = ({
    location,
    bookings,
  }: {
    location: locationType
    bookings: Array<{ node: courseBookingType }>
  }) => {
    // @ts-ignore
    const proxyCourseGroup: courseGroupType = location
    proxyCourseGroup.image = location.city.image

    const newCourses: Array<courseType> = []
    bookings.forEach(({ node }) =>
      node.courses.forEach((course) => {
        if (
          !newCourses.some(
            (newCourse) => newCourse.contentful_id === course.contentful_id
          )
        ) {
          newCourses.push(course)
        }
      })
    )
    proxyCourseGroup.courses = newCourses
    return proxyCourseGroup
  }
  const proxyCourseGroup = createProxyCourseGroup({
    location: data.contentfulLocation,
    bookings: filteredBookings,
  })

  return (
    <CourseGroupPage
      courseGroup={proxyCourseGroup}
      bookings={filteredBookings}
      url={url}
      variant="location"
      viewCoursesAlternateUrl={data.contentfulLocation.viewCoursesByTopicUrl}
    />
  )
}

export const pageQuery = graphql`
  query CourseLocation($contentful_id: String!) {
    contentfulLocation(contentful_id: { eq: $contentful_id }) {
      contentful_id
      name
      slug
      description {
        raw
      }
      faqs {
        heading
        content {
          raw
        }
        contentful_id
      }
      viewCoursesByTopicUrl
      city {
        image {
          gatsbyImageData
          title
        }
      }
      seo {
        socialImage {
          file {
            url
          }
        }
        description {
          description
        }
        title
      }
    }
    allContentfulCourseBookings(
      sort: { order: ASC, fields: startDate }
      filter: { contentful_id: { ne: "3bBK7TnvlCI8JsnjF9Ma1m" } }
    ) {
      edges {
        node {
          contentful_id
          addressFull
          addressShort
          endDate
          startDate
          stripePriceId
          priceCurrency
          price
          discountedPrice
          showVat
          priceDisplayed
          locations {
            contentful_id
            city {
              name
              contentful_id
              region {
                name
                contentful_id
              }
            }
          }
          courses {
            contentful_id
            cpdTime
            hideCoursePrice
            skillLevel
            summary {
              summary
            }
            slug
            onlineOnly
            name
            partnerLogo {
              gatsbyImageData
              title
            }
            thinkificTraining {
              courseUrl
              contentful_id
              duration
            }
            professions
            topic {
              slug
            }
          }
        }
      }
    }
  }
`
